import { SearchData, AdminFindUserInput } from 'admin/models';
import * as fromActions from './search-modal.actions';

export interface SearchModalState {
  isLoading: boolean;
  loaded: boolean;
  error: any;
  searchData: SearchData | null;
  searchParameter: AdminFindUserInput | null;
}

export const initialState: SearchModalState = {
  isLoading: false,
  loaded: false,
  error: null,
  searchData: null,
  searchParameter: null
};

export function reducer(
  state = initialState,
  action: fromActions.SearchModalActions
): SearchModalState {
  switch (action.type) {
    case fromActions.FETCH_SEARCH_RESULTS: {
      return {
        ...state,
        isLoading: true,
        searchParameter: action.searchParameter
      };
    }

    case fromActions.FETCH_SEARCH_RESULTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        loaded: false,
        error: action.error,
        searchData: null
      };
    }

    case fromActions.FETCH_SEARCH_RESULTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        loaded: true,
        error: null,
        searchData: action.data
      };
    }

    case fromActions.CLEAR_SEARCH: {
      return {
        ...initialState
      };
    }

    default:
      return state;
  }
}

export const getIsLoading = (state: SearchModalState) => state.isLoading;
export const getLoaded = (state: SearchModalState) => state.loaded;
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const getError = (state: SearchModalState) => state.error;
export const getSearchData = (state: SearchModalState) => state.searchData;
export const getSearchParameter = (state: SearchModalState) =>
  state.searchParameter;
