import { Action } from '@ngrx/store';
import { AdminFindUserInput, SearchData } from 'admin/models';

export const SEARCH = '[Search Modal] Start Search';
export const SEARCH_USER = '[Search Modal] Start Search User';

export const SEARCH_PEOPLE = '[Search Modal] Start Search PEOPLE';
export const SEARCH_PROPERTY = '[Search Modal] Start Search PROPERTY';
export const FETCH_SEARCH_RESULTS = '[Search Modal] Fetch Search Results';
export const FETCH_SEARCH_RESULTS_FAIL =
  '[Search Modal] Fetch Search Results Fail';
export const FETCH_SEARCH_RESULTS_SUCCESS =
  '[Search Modal] Fetch Search Results Success';
export const CLEAR_SEARCH = '[Search Modal] Clear Search';
export const FETCH_LATEST_FLAT_FOR_USER =
  '[Search Modal] Get Latest Flat for People Details';

export class FetchSearchResults implements Action {
  readonly type = FETCH_SEARCH_RESULTS;
  constructor(public searchParameter: AdminFindUserInput) {}
}

export class FetchSearchResultsFail implements Action {
  readonly type = FETCH_SEARCH_RESULTS_FAIL;
  constructor(public error: any) {}
}

export class FetchSearchResultsSuccess implements Action {
  readonly type = FETCH_SEARCH_RESULTS_SUCCESS;
  constructor(public data: SearchData) {}
}

export class ClearSearch implements Action {
  readonly type = CLEAR_SEARCH;
}

export type SearchModalActions =
  | FetchSearchResults
  | FetchSearchResultsFail
  | FetchSearchResultsSuccess
  | ClearSearch;
