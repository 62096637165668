import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { Apollo } from 'apollo-angular';
import { getResponseValidator } from 'libs/infrastructure';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { notificationConfig } from 'admin/config';
import { findUser, AdminUserSearchResult } from '../queries/gql-queries';
import * as fromSearchModalActions from './search-modal.actions';

@Injectable()
export class SearchModalEffects {
  private actions$ =
    inject<Actions<fromSearchModalActions.SearchModalActions>>(Actions);
  private apollo = inject(Apollo);
  private translate = inject(TranslateService);

  searchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSearchModalActions.FETCH_SEARCH_RESULTS),
      map((action: fromSearchModalActions.FetchSearchResults) => action),
      switchMap(action =>
        this.apollo
          .query({
            query: findUser,
            variables: {
              input: {
                fieldValue: action.searchParameter.fieldValue,
                fieldType: action.searchParameter.fieldType,
                userType: action.searchParameter.userType
              }
            },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            tap(getResponseValidator<AdminUserSearchResult>()),
            map(
              res =>
                new fromSearchModalActions.FetchSearchResultsSuccess({
                  data: res.data.findUser
                })
            ),
            catchError(() => [
              new fromBaseState.ShowError(
                this.translate.instant(notificationConfig.search.notFound)
              )
            ])
          )
      )
    )
  );
}
