import { Action } from '@ngrx/store';
import { LandlordUser } from '@ui/shared/models';
import {
  CustomerImportSettings,
  ObjectHierarchy,
  LandlordUserInput
} from '../../models';

export const CREATE_LANDLORD = '[Landlords] Create Landlord';
export const CREATE_LANDLORD_SUCCESS = '[Landlords] Create Landlord Success';
export const CREATE_LANDLORD_FAIL = '[Landlords] Create Landlord Fail';

export class CreateLandlord implements Action {
  readonly type = CREATE_LANDLORD;
  constructor(public input: LandlordUserInput) {}
}

export class CreateLandlordSuccess implements Action {
  readonly type = CREATE_LANDLORD_SUCCESS;
  constructor(public landlord: LandlordUser) {}
}

export class CreateLandlordFail implements Action {
  readonly type = CREATE_LANDLORD_FAIL;

  constructor(public error: string) {}
}
export const UPDATE_LANDLORD = '[Landlords] Update Landlord';
export const UPDATE_LANDLORD_SUCCESS = '[Landlords] Update Landlord Success';
export const UPDATE_LANDLORD_FAIL = '[Landlords] Update Landlord Fail';

export class UpdateLandlord implements Action {
  readonly type = UPDATE_LANDLORD;
  constructor(
    public input: LandlordUserInput,
    public id: string = null
  ) {}
}

export class UpdateLandlordSuccess implements Action {
  readonly type = UPDATE_LANDLORD_SUCCESS;
  constructor(public landlord: LandlordUser) {}
}

export class UpdateLandlordFail implements Action {
  readonly type = UPDATE_LANDLORD_FAIL;

  constructor(public error: string) {}
}

export const GET_LANDLORD = '[Landlords] Get Landlord';
export const GET_LANDLORD_SUCCESS = '[Landlords] Get Landlord Success';
export const GET_LANDLORD_FAIL = '[Landlords] Get Landlord Fail';

export class GetLandlord implements Action {
  readonly type = GET_LANDLORD;

  constructor(public id: string) {}
}

export class GetLandlordSuccess implements Action {
  readonly type = GET_LANDLORD_SUCCESS;

  constructor(public landlord: LandlordUser) {}
}

export class GetLandlordFail implements Action {
  readonly type = GET_LANDLORD_FAIL;

  constructor(public error: string) {}
}

export const SELECT_LANDLORD = '[Landlords] Select Landlord';

export class SelectLandlord implements Action {
  readonly type = SELECT_LANDLORD;

  constructor(public id: string) {}
}

export const NEXT_STEP = '[Landlord Wizard] Next Step';
export const PREVIOUS_STEP = '[Landlord Wizard] Previous Step';
export const SET_STEP = '[Landlord Wizard] Set Step';

export class NextStep implements Action {
  readonly type = NEXT_STEP;

  constructor(
    public completedStep: string,
    public landlordId: string
  ) {}
}

export class PreviousStep implements Action {
  readonly type = PREVIOUS_STEP;
  constructor(
    public currentStep: number,
    public landlordId: string
  ) {}
}

export class SetStep implements Action {
  readonly type = SET_STEP;
  constructor(
    public currentStep: number,
    public landlordId: string
  ) {}
}

export const RESET_WIZARD = `[Landlord Wizard] Reset Wizard`;
export class ResetWizard implements Action {
  readonly type = RESET_WIZARD;
}

export const GET_OBJECT_HIERARCHY = '[ObjectHierarchy] Get Object Hierarchy';
export const GET_OBJECT_HIERARCHY_SUCCESS =
  '[ObjectHierarchy] Get Object Hierarchy Success';
export const GET_OBJECT_HIERARCHY_FAIL =
  '[ObjectHierarchy] Get Object Hierarchy Fail';

export class GetObjectHierarchy implements Action {
  readonly type = GET_OBJECT_HIERARCHY;

  constructor(public customerId: number) {}
}

export class GetObjectHierarchySuccess implements Action {
  readonly type = GET_OBJECT_HIERARCHY_SUCCESS;

  constructor(public objectHierarchy: ObjectHierarchy[]) {}
}

export class GetObjectHierarchyFail implements Action {
  readonly type = GET_OBJECT_HIERARCHY_FAIL;

  constructor(public error: string) {}
}

export const CREATE_OBJECT_HIERARCHY =
  '[ObjectHierarchy] Create Object Hierarchy';
export const CREATE_OBJECT_HIERARCHY_SUCCESS =
  '[ObjectHierarchy] Create Object Hierarchy Success';
export const CREATE_OBJECT_HIERARCHY_FAIL =
  '[ObjectHierarchy] Create Object Hierarchy Fail';

export class CreateObjectHierarchy implements Action {
  readonly type = CREATE_OBJECT_HIERARCHY;
  constructor(
    public customerId: number,
    public objectHierarchy: ObjectHierarchy
  ) {}
}

export class CreateObjectHierarchySuccess implements Action {
  readonly type = CREATE_OBJECT_HIERARCHY_SUCCESS;
  constructor(public objectHierarchy: ObjectHierarchy) {}
}

export class CreateObjectHierarchyFail implements Action {
  readonly type = CREATE_OBJECT_HIERARCHY_FAIL;

  constructor(public error: string) {}
}

export const UPDATE_OBJECT_HIERARCHY =
  '[ObjectHierarchy] Update Object Hierarchy';
export const UPDATE_OBJECT_HIERARCHY_SUCCESS =
  '[ObjectHierarchy] Update Object Hierarchy Success';
export const UPDATE_OBJECT_HIERARCHY_FAIL =
  '[ObjectHierarchy] Update Object Hierarchy Fail';

export class UpdateObjectHierarchy implements Action {
  readonly type = UPDATE_OBJECT_HIERARCHY;
  constructor(
    public customerId: number,
    public administrationUnitType: number,
    public objectHierarchy: ObjectHierarchy
  ) {}
}

export class UpdateObjectHierarchySuccess implements Action {
  readonly type = UPDATE_OBJECT_HIERARCHY_SUCCESS;
  constructor(public objectHierarchy: ObjectHierarchy) {}
}

export class UpdateObjectHierarchyFail implements Action {
  readonly type = UPDATE_OBJECT_HIERARCHY_FAIL;
  constructor(public error: string) {}
}

export const DELETE_OBJECT_HIERARCHY =
  '[ObjectHierarchy] Delete Object Hierarchy';
export const DELETE_OBJECT_HIERARCHY_SUCCESS =
  '[ObjectHierarchy] Delete Object Hierarchy Success';
export const DELETE_OBJECT_HIERARCHY_FAIL =
  '[ObjectHierarchy] Delete Object Hierarchy Fail';

export class DeleteObjectHierarchy implements Action {
  readonly type = DELETE_OBJECT_HIERARCHY;
  constructor(
    public customerId: number,
    public administrationUnitType: number
  ) {}
}

export class DeleteObjectHierarchySuccess implements Action {
  readonly type = DELETE_OBJECT_HIERARCHY_SUCCESS;
}

export class DeleteObjectHierarchyFail implements Action {
  readonly type = DELETE_OBJECT_HIERARCHY_FAIL;
  constructor(public error: string) {}
}

export const GET_CUSTOMER_IMPORT_SETTINGS =
  '[CustomerImportSettings] Get Import Settings';
export const GET_CUSTOMER_IMPORT_SETTINGS_SUCCESS =
  '[CustomerImportSettings] Get Import Settings Success';
export const GET_CUSTOMER_IMPORT_SETTINGS_FAIL =
  '[CustomerImportSettings] Get Import Settings Fail';

export class GetObjectHierarchySettings implements Action {
  readonly type = GET_CUSTOMER_IMPORT_SETTINGS;
  constructor(public customer: number) {}
}

export class GetObjectHierarchySettingsSuccess implements Action {
  readonly type = GET_CUSTOMER_IMPORT_SETTINGS_SUCCESS;
  constructor(public customerImportSettings: CustomerImportSettings) {}
}

export class GetObjectHierarchySettingsFail implements Action {
  readonly type = GET_CUSTOMER_IMPORT_SETTINGS_FAIL;
  constructor(public error: string) {}
}

export const UPDATE_CUSTOMER_IMPORT_SETTINGS =
  '[CustomerImportSettings] Update Settings';
export const UPDATE_CUSTOMER_IMPORT_SETTINGS_SUCCESS =
  '[CustomerImportSettings] Update Settings Success';
export const UPDATE_CUSTOMER_IMPORT_SETTINGS_FAIL =
  '[CustomerImportSettings] Update Settings Fail';

export class UpdateObjectHierarchySettings implements Action {
  readonly type = UPDATE_CUSTOMER_IMPORT_SETTINGS;
  constructor(
    public customer: number,
    public importSettings: CustomerImportSettings
  ) {}
}

export class UpdateObjectHierarchySettingsSuccess implements Action {
  readonly type = UPDATE_CUSTOMER_IMPORT_SETTINGS_SUCCESS;
}

export class UpdateObjectHierarchySettingsFail implements Action {
  readonly type = UPDATE_CUSTOMER_IMPORT_SETTINGS_FAIL;
  constructor(public error: string) {}
}

export type LandlordsAction =
  | CreateLandlord
  | CreateLandlordSuccess
  | CreateLandlordFail
  | UpdateLandlord
  | UpdateLandlordSuccess
  | UpdateLandlordFail
  | GetLandlord
  | GetLandlordSuccess
  | GetLandlordFail
  | SelectLandlord
  | NextStep
  | PreviousStep
  | SetStep
  | ResetWizard
  | CreateObjectHierarchy
  | CreateObjectHierarchySuccess
  | CreateObjectHierarchyFail
  | UpdateObjectHierarchy
  | UpdateObjectHierarchySuccess
  | UpdateObjectHierarchyFail
  | GetObjectHierarchy
  | GetObjectHierarchySuccess
  | GetObjectHierarchyFail
  | DeleteObjectHierarchy
  | DeleteObjectHierarchySuccess
  | DeleteObjectHierarchyFail
  | GetObjectHierarchySettings
  | GetObjectHierarchySettingsSuccess
  | GetObjectHierarchySettingsFail
  | UpdateObjectHierarchySettings
  | UpdateObjectHierarchySettingsSuccess
  | UpdateObjectHierarchySettingsFail;
