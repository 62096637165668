import { LandlordUser } from '@ui/shared/models';
import { ActionState, ActionStateCreator } from 'libs/state-utils';
import { CustomerImportSettings, ObjectHierarchy } from '../../models';
import * as fromActions from './landlords.actions';

export interface LandlordState {
  landlord: LandlordUser;
  landlordActionState: ActionState;
  nextProductId: string;
  selectedLandlordId: string | null;
  CRUDActionState: ActionState;
  currentStepNumber: number;
  completedSteps: string[];
  objectHierarchyData: ObjectHierarchy[];
  objectHierarchyActionState: ActionState;
  customerImportSettings: CustomerImportSettings;
  customerImportSettingsActionState: ActionState;
}

export const initialState: LandlordState = {
  landlord: {},
  landlordActionState: ActionStateCreator.create(),
  nextProductId: null,
  selectedLandlordId: null,
  CRUDActionState: ActionStateCreator.create(),
  currentStepNumber: 1,
  completedSteps: [],
  objectHierarchyData: [],
  objectHierarchyActionState: ActionStateCreator.create(),
  customerImportSettings: null,
  customerImportSettingsActionState: ActionStateCreator.create()
};

export function reducer(
  state = initialState,
  action: fromActions.LandlordsAction
): LandlordState {
  switch (action.type) {
    case fromActions.CREATE_LANDLORD:
    case fromActions.UPDATE_LANDLORD: {
      return {
        ...state,
        CRUDActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.CREATE_LANDLORD_SUCCESS: {
      return {
        ...state,
        landlord: action.landlord,
        CRUDActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.UPDATE_LANDLORD_SUCCESS: {
      return {
        ...state,
        CRUDActionState: ActionStateCreator.onSuccess(),
        landlord: action.landlord
      };
    }

    case fromActions.CREATE_LANDLORD_FAIL:
    case fromActions.UPDATE_LANDLORD_FAIL: {
      return {
        ...state,
        CRUDActionState: ActionStateCreator.onError(new Error(action.error))
      };
    }

    case fromActions.GET_LANDLORD: {
      return {
        ...state,
        selectedLandlordId: action.id,
        landlordActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.GET_LANDLORD_SUCCESS: {
      return {
        ...state,
        landlord: action.landlord,
        landlordActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.GET_LANDLORD_FAIL: {
      return {
        ...state,
        landlordActionState: ActionStateCreator.onError(new Error(action.error))
      };
    }

    case fromActions.NEXT_STEP: {
      const currentStepNumber = state.currentStepNumber + 1;
      const completedSteps = [...state.completedSteps, action.completedStep];

      return {
        ...state,
        currentStepNumber,
        completedSteps
      };
    }

    case fromActions.PREVIOUS_STEP: {
      const currentStepNumber = state.currentStepNumber - 1;

      return {
        ...state,
        currentStepNumber
      };
    }

    case fromActions.SET_STEP: {
      const currentStepNumber = action.currentStep;
      return {
        ...state,
        currentStepNumber
      };
    }

    case fromActions.RESET_WIZARD: {
      return {
        ...initialState
      };
    }

    case fromActions.GET_OBJECT_HIERARCHY: {
      return {
        ...state,
        objectHierarchyActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.GET_CUSTOMER_IMPORT_SETTINGS: {
      return {
        ...state,
        customerImportSettingsActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.GET_OBJECT_HIERARCHY_SUCCESS: {
      return {
        ...state,
        objectHierarchyData: action.objectHierarchy,
        objectHierarchyActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.GET_CUSTOMER_IMPORT_SETTINGS_SUCCESS: {
      return {
        ...state,
        customerImportSettings: action.customerImportSettings,
        customerImportSettingsActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.GET_OBJECT_HIERARCHY_FAIL:
    case fromActions.UPDATE_OBJECT_HIERARCHY_FAIL:
    case fromActions.DELETE_OBJECT_HIERARCHY_FAIL: {
      return {
        ...state,
        objectHierarchyActionState: ActionStateCreator.onError(
          new Error(action.error)
        )
      };
    }

    case fromActions.GET_CUSTOMER_IMPORT_SETTINGS_FAIL:
    case fromActions.UPDATE_CUSTOMER_IMPORT_SETTINGS_FAIL: {
      return {
        ...state,
        customerImportSettingsActionState: ActionStateCreator.onError(
          new Error(action.error)
        )
      };
    }

    case fromActions.CREATE_OBJECT_HIERARCHY_SUCCESS:
    case fromActions.DELETE_OBJECT_HIERARCHY_SUCCESS:
    case fromActions.UPDATE_OBJECT_HIERARCHY_SUCCESS: {
      return {
        ...state,
        objectHierarchyActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.UPDATE_CUSTOMER_IMPORT_SETTINGS_SUCCESS: {
      return {
        ...state,
        customerImportSettingsActionState: ActionStateCreator.onSuccess()
      };
    }

    default: {
      return state;
    }
  }
}

export const getSelectedLandlordId = (state: LandlordState) =>
  state.selectedLandlordId;
export const getLandlord = (state: LandlordState) => state.landlord;
export const getLandlordActionState = (state: LandlordState) =>
  state.landlordActionState;
export const getCRUDActionState = (state: LandlordState) =>
  state.CRUDActionState;
export const getNextProductId = (state: LandlordState) => state.nextProductId;
export const getCurrentStepNumber = (state: LandlordState) =>
  state.currentStepNumber;
export const getCompletedStep = (state: LandlordState) => state.completedSteps;
export const getObjectHierarchy = (state: LandlordState) =>
  state.objectHierarchyData;
export const getCustomerImportSettings = (state: LandlordState) =>
  state.customerImportSettings;
export const getObjectHierarchyLoadingStatus = (state: LandlordState) =>
  state.objectHierarchyActionState.pending;
export const getObjectHierarchyActionState = (state: LandlordState) =>
  state.objectHierarchyActionState;
export const getCustomerImportSettingsActionState = (state: LandlordState) =>
  state.customerImportSettingsActionState;
