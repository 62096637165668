import { LandlordUser, Pagination, Customer } from '@ui/shared/models';
import { Invoice } from '@ui/shared/models';

import { ActionState, ActionStateCreator } from 'libs/state-utils';

import * as fromActions from './landlord.actions';

export interface LandlordState {
  usersData: LandlordUser[];
  customersData: Customer[];
  invoicesData: Invoice[];
  landlordActionState: ActionState;
  pagination: Pagination;
}

export const initialState: LandlordState = {
  usersData: [],
  customersData: [],
  invoicesData: [],
  landlordActionState: ActionStateCreator.create(),
  pagination: null
};

export function reducer(
  state = initialState,
  action: fromActions.LandlordAction
): LandlordState {
  switch (action.type) {
    case fromActions.FETCH_USERS_SUCCESS: {
      return {
        ...state,
        landlordActionState: ActionStateCreator.onSuccess(),
        usersData: action.data,
        pagination: action.pagination
      };
    }

    case fromActions.FETCH_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        landlordActionState: ActionStateCreator.onSuccess(),
        customersData: action.data,
        pagination: action.pagination
      };
    }

    case fromActions.FETCH_INVOICES_SUCCESS: {
      return {
        ...state,
        landlordActionState: ActionStateCreator.onSuccess(),
        invoicesData: action.data,
        pagination: action.pagination
      };
    }

    case fromActions.CHANGE_USER_ENABLED_SUCCESS: {
      state.usersData.map(user => {
        if (user.id === action.data.id) user.enabled = action.data.enabled;
      });

      return {
        ...state,
        landlordActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.FETCH_CUSTOMER_BY_ID_SUCCESS: {
      return {
        ...state,
        landlordActionState: ActionStateCreator.onSuccess(),
        customersData: action.data
      };
    }

    case fromActions.FETCH_INVOICE_BY_ID_SUCCESS: {
      return {
        ...state,
        landlordActionState: ActionStateCreator.onSuccess(),
        invoicesData: action.data
      };
    }
    case fromActions.SEARCH_LANDLORD_USER_SUCCESS: {
      return {
        ...state,
        landlordActionState: ActionStateCreator.onSuccess(),
        usersData: action.data
      };
    }

    case fromActions.IMPERSONATE_USER_SUCCESS:
    case fromActions.CANCEL_INVOICE_SUCCESS: {
      return {
        ...state,
        landlordActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.FETCH_USERS:
    case fromActions.FETCH_CUSTOMERS:
    case fromActions.FETCH_INVOICES:
    case fromActions.CHANGE_USER_ENABLED:
    case fromActions.FETCH_CUSTOMER_BY_ID:
    case fromActions.FETCH_INVOICE_BY_ID:
    case fromActions.SEARCH_LANDLORD_USER:
    case fromActions.IMPERSONATE_USER:
    case fromActions.CANCEL_INVOICE: {
      return {
        ...state,
        landlordActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.ENABLE_EXTERNAL_PRICING_SUCCESS: {
      const customersData = state.customersData.map(customer => {
        if (customer.id === action.id) {
          return {
            ...customer,
            externalPricing: action.enable
          };
        }
        return customer;
      });

      return {
        ...state,
        customersData
      };
    }

    case fromActions.FETCH_USERS_FAIL:
    case fromActions.FETCH_CUSTOMERS_FAIL:
    case fromActions.FETCH_INVOICES_FAIL:
    case fromActions.CHANGE_USER_ENABLED_FAIL:
    case fromActions.FETCH_CUSTOMER_BY_ID_FAIL:
    case fromActions.FETCH_INVOICE_BY_ID_FAIL:
    case fromActions.SEARCH_LANDLORD_USER_FAIL:
    case fromActions.IMPERSONATE_USER_FAIL:
    case fromActions.CANCEL_INVOICE_FAIL: {
      return {
        ...state,
        landlordActionState: ActionStateCreator.onError(action.error)
      };
    }

    default:
      return state;
  }
}

export const getLandlordUsersData = (state: LandlordState) => state.usersData;
export const getLandlordCustomersData = (state: LandlordState) =>
  state.customersData;
export const getLandlordInvoicesData = (state: LandlordState) =>
  state.invoicesData;
export const getLandlordActionState = (state: LandlordState) =>
  state.landlordActionState;
export const getPagination = (state: LandlordState) => state.pagination;
