import { createSelector } from '@ngrx/store';

import { getSearchModalState } from '../reducers';
import * as fromSearchDataReducers from './search-modal.reducers';

export const getSearchDataLoaded = createSelector(
  getSearchModalState,
  fromSearchDataReducers.getLoaded
);
export const getSearchDataIsLoading = createSelector(
  getSearchModalState,
  fromSearchDataReducers.getIsLoading
);
export const getSearchDataError = createSelector(
  getSearchModalState,
  fromSearchDataReducers.getError
);
export const getSearchData = createSelector(
  getSearchModalState,
  fromSearchDataReducers.getSearchData
);
export const getSearchParameter = createSelector(
  getSearchModalState,
  fromSearchDataReducers.getSearchParameter
);

export const getSearchOptions = createSelector(
  getSearchParameter,
  searchParameter => {
    return {
      ...searchParameter
    };
  }
);
